.container {
    width: fit-content;
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 1px;
    border-radius: 8px;
    background-color: var(--addable-gray);
}

.button {
    height: 26px;
    padding: 0 10px;
    border-radius: 8px;
    color: var(--disabled);
}

.button > * {
    color: var(--disabled);
}

.active {
    background-color: var(--main-white);
}

.active > * {
    color: var(--main-black);
}
