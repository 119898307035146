:root { 
 /* colors */
 --main-white: #fff;
 --main-black: #000;
 --main-purple: #466DF2;
 --main-lightgray: #F6F7F7;

 --addable-gray: #E6E6E6;
 --addable-red: #F83C3C;

 --hover-gray: #D9D9D9;
 --hover-purple: #466DF2;

 --click-purple: #1538AF;

 --disabled: #B3B3B3;
}