@font-face {
    src: url('../../assets/fonts/Inter-SemiBold.ttf') format('truetype');
    font-family: 'Unbounded';
    font-weight: 600;
}

@font-face {
 src: url('../../assets/fonts/Inter-Regular.ttf') format('truetype');
 font-family: 'Unbounded';
 font-weight: 400;
}
